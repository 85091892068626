import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Base } from '../../Baseurl/Baseurl';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies

const ContactCard = () => {
  const [mapImg, setMapImg] = useState({
    image_1: "",
    image_2: "",
  });

  // Function to fetch the contact image data
  const imgData = () => {
    axios.get(`${Base}/api/user/view/contact`)
      .then((e) => {
        setMapImg(e.data.data); // Set the data to state
        Cookies.set('contactImages', JSON.stringify(e.data.data), { expires: 7 }); // Store data in cookies for 7 days
      })
      .catch((err) => {
        console.log(err.message); // Log error if API call fails
      });
  };

  useEffect(() => {
    // Check if contact image data exists in cookies
    const storedData = Cookies.get('contactImages');
    if (storedData) {
      setMapImg(JSON.parse(storedData)); // Use data from cookies if available
    }
    imgData(); // Fetch data from API if no data in cookies

  }, []); // Empty dependency array to only run once on component mount

  return (
    <div className="container my-5">
      <div className="section-title-area">
        <h2 className="section-title" style={{ color: 'black' }}>Contact Us</h2>
        <div className="section-divider" />
      </div>
      <div className="row align-items-center">
        {/* Left Column */}
        <div className="col-md-6 text-center p-0">
          <img
            src={mapImg?.image_1 || ""}
            alt="Left Side"
            className="img-fluid"
          />
        </div>
        {/* Right Column */}
        <div className="col-md-6 text-center p-0">
          <img
            src={mapImg?.image_2 || ""}
            alt="Right Side"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
